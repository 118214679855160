import React, { Component } from 'react';
import {get_price} from '../.././Prices'
import {get_theme} from "../../Theme.js";
import './Extras.css';

class Extras extends Component{
    render(){
        const theme = get_theme(this.props.zone.theme);

        return (
            <div className={'Extras'} style={theme.background_color_theme}>
                <div className={'zone_name'} style={theme.header_theme}>{this.props.zone.zoneTitle}</div>
                <div className={'items_wrapper'}>
                    <div className={'items'}>
                        {this.props.zone.zone.map(item => (
                            <div className='item' key={item.item_id}>
                                <div className={'name'} style={theme.name_theme}>{item.name}</div>
                                <div className={'price_wrapper'}>{get_price(item, theme.size_theme, theme.price_theme)}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        )
    }
}
export default Extras
