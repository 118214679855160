import React, { Component } from 'react';
import Slider from "react-slick";
import {get_price} from '../.././Prices'
import {get_theme} from "../../Theme.js";
import './Lascaobas.css';

class Lascaobas extends Component{

    constructor(props){
        super(props);
        let slide_to_show = (props.zone.zone.length <= 5 ? props.zone.zone.length : 5)
        if (parseInt(this.props.zone.slides_to_show) > 0) {
            slide_to_show = parseInt(this.props.zone.slides_to_show);
        }
        this.state = {
            slick : {
                setting : {
                    speed: 600,
                    slidesToShow: slide_to_show,
                    vertical: true,
                    autoplaySpeed: parseInt(props.zone.duration),
                    autoplay: true,
                    accessibility: false,
                    arrows: false,
                    fade: false,
                    pauseOnHover: false,
                    slidesToScroll: 1,
                    infinite: true,
                }
            }
        }
    }

    render(){
        let {slick} =  this.state;
        const theme = get_theme(this.props.zone.theme);
        return(
            <div className={'Lascaobas'} style={theme.background_color_theme}>
                <div className={'zoneName'} style={theme.header_theme}>{this.props.zone.zoneTitle}</div>
                <Slider className={'items'} {...slick.setting}>
                    {
                        this.props.zone.zone.map(item => (
                            <div className={'item'} key={item.item_id} >
                                <div className="infos" style={theme.background_color_theme}>
                                    <div className="name" style={theme.name_theme}>
                                        <div>{item.name}</div>
                                    </div>
                                    {get_price(item, theme.size_theme, theme.price_theme)}
                                </div>
                            </div>
                        ))}
                </Slider>
            </div>
        )
    }
}
export default Lascaobas;