import React, { Component } from 'react';
import './Horizontal.css';
import Feeds from './Views/Feeds';
import Generic from './Generic';

class Horizontal extends Component{

    constructor(props){
        super(props);

        this.state = {
            isLoaded: false,
            feeds :  []
        };
    }

    render(){

        return(
            <div className={'Horizontal'}>
                <div className={'wrapper'}>
                    <Generic zone={this.props.zone1}></Generic>
                    <div className={'Lascaobas_wrapper'}>
                        <Generic className={'zone1'} zone={this.props.zone2}></Generic>
                        <Generic className={'zone1'} zone={this.props.zone3}></Generic>
                    </div>
                </div>
                <Feeds></Feeds>
            </div>
        )
    }
}

export default Horizontal;