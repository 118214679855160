import React, { Component } from 'react';
import Generic from './Generic';
import './One.css';
import Infos from "./Views/Infos";
//import Infos from "./Views/Infos";

class One extends Component{

    render(){
        return(
            <div>
                <Infos banner={this.props.banner}></Infos>
                <div className={'one'}>
                    <Generic zone={this.props.zone1}></Generic>
                </div>
            </div>
        )
    }
}
export default One;
