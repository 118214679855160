import React, { Component } from 'react';
import Slider from "react-slick";
import {get_description, get_image, get_numbering} from "../../Common.js";
import {get_new, get_price, get_promo} from '../.././Prices'
import {get_theme} from "../../Theme.js";
import './List.css';

class List extends Component{

    constructor(props){
        super(props);
        let slide_to_show = (props.zone.zone.length <= 5 ? props.zone.zone.length : 5)
        if (parseInt(this.props.zone.slides_to_show) > 0) {
            slide_to_show = parseInt(this.props.zone.slides_to_show);
        }
        this.state = {
            slick : {
                setting : {
                    speed: 600,
                    slidesToShow: slide_to_show,
                    vertical: true,
                    autoplaySpeed: parseInt(props.zone.duration),
                    autoplay: true,
                    accessibility: false,
                    arrows: false,
                    fade: false,
                    pauseOnHover: false,
                    slidesToScroll: 1,
                    infinite: true,
                }
            }
        }
    }

    render(){
        const {slick} = this.state;
        const theme   = get_theme(this.props.zone.theme);

        return(
           <div className={'List'}>
               <div className={'zoneName'} style={theme.header_theme}>{this.props.zone.zoneTitle}</div>
               <div className={'list_wrapper'}>
                   <div className={'list_background color'} style={theme.background_color_theme}></div>
                   <div className={'list_background image'} style={theme.background_image_theme}></div>
                   <Slider className={'items'} {...slick.setting}>
                       {
                           this.props.zone.zone.map(item => (
                               <div className={'list'} key={item.item_id}>
                                   <div className="infos">
                                       {get_numbering(item, theme, this.props.zone.start_pos, this.props.zone.show_pos)}
                                       {get_image (item)}
                                       <div className="name_wrapper">
                                           <div className={'name'} style={theme.name_theme}>{item.name}</div>
                                           {get_description(item, theme)}
                                           {get_new(item)}
                                           {get_promo(item)}
                                       </div>
                                   </div>
                                   <div className={'price_wrapper'}>
                                       {get_price(item, theme.size_theme, theme.price_theme)}
                                   </div>
                               </div>
                           ))}
                   </Slider>
               </div>
           </div>
        )
    }
}

export default List;