import React from 'react';
import Config from './../Config';
import YouTube from 'react-youtube'; /*https://developers.google.com/youtube/player_parameters*/ ///https://gist.github.com/mikecrittenden/28fe4877ddabff65f589311fd5f8655c
import Vimeo from '@u-wave/react-vimeo'; /*https://github.com/u-wave/react-vimeo*/
const API_KEY = 'AIzaSyCk3OnmCRqLSpPvSXsYnHoy1obthSxrxL4';

export const get_media_type = (media, id='') =>{
    let slide = ''
    let media_id = media.id + id;
    let source_video = media.source_video;

    if (media.source_origin === "" && source_video !== undefined){
        if (source_video !== null) {
            source_video = Config.HTTP + media.source_video;
        }
    }
    if(media.media_type === 'image' || media.media_type === 'display') {
        slide = <div className={'image_wrapper'} id={`image-${media_id}`} data-duration={media.duration}>
            <div className={'image'} style={{ backgroundImage: `url(${Config.HTTP}://${media.medium})`}}></div>
            <div className={'image_blur'} style={{ backgroundImage: `url(${Config.HTTP}://${media.small})`}} ></div>
        </div>
    } else {
        if(media.source_video_type === 'mp4') {
            slide = <video  id={`video-${media_id}`} muted className={'video_MP4'} src={source_video}></video>
        }
        if(media.source_video_type === 'youtube') {
            slide = <YouTube id={`youtube-${media_id}`} className={'video_YOUTUBE'} videoId={media.source_transform} opts={this.state.opts} slider={this.slider} onReady={this.onPlayerReady}  onStateChange={this.onPlayerStateChange} /*onEnd={this.onPlayerStateChange}*//>
        }
        if(media.source_video_type === 'vimeo') {
            slide = <Vimeo id={`vimeo-${media_id}`} className={'video_VIMEO'} showByline={false} background={true} width={640} height={480}  video={media.source_transform}/>
        }
    }

    return slide
}

export const loadYoutubeApi = () =>{
    const script = document.createElement("script");
    script.src = "https://apis.google.com/js/client.js";

    script.onload = () => {
        window.gapi.load('client', () => {
            window.gapi.client.setApiKey(API_KEY);
            window.gapi.client.load('youtube', 'v3', () => {
                this.setState({ gapiReady: true });
            });
        });
    };

    document.body.appendChild(script);
}

export const playDuration = (slider, dynamicId) => {
    let id = document.getElementsByClassName(`${dynamicId}`)[0]
    let slick_current = id.getElementsByClassName('slick-current')[0]
    if(slick_current !== undefined) {
        if (slick_current.getElementsByClassName('video_MP4')[0] !== undefined) {
            let video_tag = slick_current.getElementsByClassName('video_MP4')[0]
            let video_id = video_tag.getAttribute('id');
            if (video_id !== '') {
                let video = document.getElementById(video_id)
                slider.slickPause()
                video.play()
                video.addEventListener('ended', function () {
                    slider.slickNext()
                    slider.slickPlay()
                })
            }
        }

        if(slick_current.getElementsByClassName('image_wrapper')[0] !== undefined) {
            let imageTag  = slick_current.getElementsByClassName('image_wrapper')[0]
            let duration  = imageTag.getAttribute('data-duration');
            slider.slickPause()
            setTimeout(function(){
                slider.slickNext()
                slider.slickPlay()
            }, duration);
        }

        if(slick_current.getElementsByClassName('video_YOUTUBE')[0] !== undefined) {
            let video_tag = slick_current.getElementsByClassName('video_YOUTUBE')[0]
            let video_id = video_tag.getAttribute('id');
            if (video_id !== '') {
                //todo
            }
            //todo
        }

        if(slick_current.getElementsByClassName('video_VIMEO')[0] !== undefined) {
            //todo
        }
    }
}