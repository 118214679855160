import React, { Component } from 'react';
import './Infos.css';
import '../../../assets/weathericons/css/weather-icons.min.css'
import '../../../assets/weathericons/css/weather-icons-wind.min.css'
import Ticker from 'react-ticker'
import Config from "../../../Config";

class Infos extends Component {

    constructor(props){
        super(props)
        this.state = {
            client_id :  localStorage.getItem('client_id'),
            temp: '',
            icon: '',
            time: '',
            isLoaded: false,
            text_color: '#fff',
            bg_Color: '#000'
        }
    }

    get_time(){

        let d = new Date();

        let h = d.getHours();
        if (h < 10) {
            h = "0" + h;
        }
        let m = d.getMinutes();
        if (m < 10) {
            m = "0" + m;
        }

        this.setState({
            time: h + ":" + m
        })
    }
    //To do: manage when they is no data in php file
    componentDidMount(){
        const data = {
            method : 'get_info_theme',
            client_id : this.state.client_id
        }

        let formData = new FormData();
        for(let i in data){
            formData.append(i,data[i]);
        }

        fetch(Config.API,{
            method: 'POST',
            body: formData,
        })
        .then(res => res.json())
        .then(
            (result) => {
                if(result.colors !== 0) {
                    this.setState({
                        text_color: result.colors[0].text_color,
                        bg_Color: result.colors[0].bg_color
                    })
                }

            },
            (error) => {
                this.setState({
                    isLoaded: false,
                })
            }
        )
    }

    componentWillMount() {
        this.interval = setInterval(() => {
            this.get_time()
        }, 1000);
        fetch("https://api.openweathermap.org/data/2.5/weather?q=Montreal&units=metric&appid=ffd43f7333973a3aa0a01becfb7391f1",{
        })
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    isLoaded: true,
                    temp: Math.round(result.main.temp)+ "°C",
                    icon: result.weather[0].main.toLowerCase()
                });
            },
            (error) => {
                this.setState({
                    isLoaded: false,
                    temp: 'NA'
                });
            }
        );
    }

    get_logo(logo){
        let tag = ''
        if (logo !== false) {
            tag = <div className={'logo'} style={{backgroundImage: `url(${localStorage.getItem('logo')})`}}></div>
        }
        return tag
    }

    get_messages(messages){
        if (messages !== '') {
            return <div className={'messages_wrapper'}>
                    <Ticker className={'messages'} speed={10}>
                        {({ index }) => (
                            <>
                                {messages.map(msg => (
                                    <span className={'message'}  key={msg.id}>{msg.message}</span>
                                ))}
                            </>
                        )}
                    </Ticker>
                  </div>
        }
    }

    render (){
        const {temp, time, icon} = this.state;

        let theme = this.props.banner.theme;
        let messages = this.props.banner.messages;

        let content = "";
        if (this.props.banner.is_visible === 'true') {
            content = <div className={'Infos'} style={{ backgroundColor: `${theme.background_color}`,color: `${theme.text_color}`, fontFamily: `${theme.text_font}`}}>
                {this.get_logo(this.props.logo)}
                {this.get_messages(messages)}
                <div className={'weather_time'}>
                    <div className={'time'}>{time}</div>
                    <div className={'weather'}>
                        <span>{temp}</span>
                        <span className={`wi wi-${icon}`}></span>
                    </div>
                </div>
            </div>
        }

        return (
            <div>
                {content}
            </div>
        )
    }
}

export default Infos;