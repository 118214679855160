const environment = process.env.NODE_ENV || 'development';

let Config;
let os = require("os");
let hostname = os.hostname();
if (environment === 'development') {
    Config = {
      API: 'http://api.dev.catodik.com',
      DOMAIN: 'http://admin.dev.catodik.com',
      HTTP  : 'http://'
   }
} else {
    let api_url = 'https://api.catodik.com';
    let domain  = 'https://admin.catodik.com';
    let http    = 'https://';

    if (hostname.includes('staging') === true) {
        api_url = 'https://api.staging.catodik.com';
        domain  = 'https://admin.staging.catodik.com';
    }

    Config = {
      API: api_url,
      DOMAIN: domain,
      HTTP  : http
   }
}

export default Config;