import Config from "../Config";

export const getDate = () => {
    let date = new Date()
    let yyyy = date.getFullYear().toString()
    let mm = (date.getMonth()+1).toString()
    let dd  = date.getDate().toString()

    let mmChars = mm.split('')
    let ddChars = dd.split('')

    return yyyy + '-' + (mmChars[1]?mm:"0"+mmChars[0]) + '-' + (ddChars[1]?dd:"0"+ddChars[0]);
}

export const validate_date = (schedule) =>{
    let date_start   = Date.parse(schedule.schedule_date_start);
    let date_end     = Date.parse(schedule.schedule_date_end);
    let date_current = Date.parse(getDate());

    if(date_start !== null && date_end !== null){
        if(Math.abs(date_start - date_end) >= Math.abs(date_start - date_current)){
            return true
        }else{
            return false
        }
    }else{
        return false
    }

}

export const validate_day = (schedule) =>{
    let day       = new Date()
    let today     = day.getDay()
    let response  = false;
    let days      = JSON.parse(schedule.days)
    if(schedule.days !== null) {
        if (days.day_1 === true && today === 0 && days.day_1 !== null) {
            response = true
        }
        if (days.day_2 === true && today === 1 && days.day_2 !== null) {
            response = true
        }
        if (days.day_3 === true && today === 2 && days.day_3 !== null) {
            response = true
        }
        if (days.day_4 === true && today === 3 && days.day_4 !== null) {
            response = true
        }
        if (days.day_5 === true && today === 4 && days.day_5 !== null) {
            response = true
        }
        if (days.day_6 === true && today === 5 && days.day_6 !== null) {
            response = true
        }
        if (days.day_7 === true && today === 6 && days.day_7 !== null) {
            response = true
        }
    }
    return response
}

export const convert_minute = (time) =>{
    if(time !== null) {
        let times = time;
        let times_part = times.split(":");
        let times_hour = times_part[0]
        let times_min = times_part[1]
        return (times_hour * 60) + times_min
    }
}

export const validate_time = (schedule) =>{
    let date            = new Date();
    let current_time    = date.getHours()+":"+date.getMinutes()+":"+date.getSeconds()
    let minutes_current = convert_minute(current_time)
    let minutes_start   = convert_minute(schedule.schedule_time_start)
    let minutes_end     = convert_minute(schedule.schedule_time_end)
    if(parseInt(minutes_start) <= parseInt(minutes_current) && parseInt(minutes_end) >= parseInt(minutes_current)){
        return true
    }
}

export const validate_schedule = (schedule) =>{
    if(schedule.schedule_id !== null && schedule.is_schedule !== 'false') {
        // console.log("date: " + schedule.name + " : "+ validate_date(schedule))
        // console.log("day: " + schedule.name + " : " + validate_day(schedule))
        // console.log("time: " + schedule.name + " : "+ validate_time(schedule))
        if(validate_date(schedule) === true && validate_day(schedule)  === true && validate_time(schedule) === true){
            return true
        }else{
            return false
        }
    }
    else{
        return false
    }
}

export const updtate_schedule_refresh = (schedule, status) => {
    let data = {
        'method'      : 'set_schedule_refresh',
        'id'          : schedule.id,
        'status'      : status
    };
    let fd = new FormData();
    for(let i in data){
        fd.append(i,data[i]);
    }
    fetch(Config.API,{
        method: 'POST',
        dataType: 'html',
        body: fd
    })
    .then(res => res.json())
    .then(
        (result) => {
            if(result.status_update === true){
                window.location.reload()
            }
        },
        (error) => {
        }
    );
}

export const validate_schedule_display = (schedule) =>{
    let is_schedule      = validate_schedule(schedule)
    if(is_schedule === true && schedule.refresh === 'false'){
        updtate_schedule_refresh(schedule, true)
    }

    if(is_schedule === false && schedule.refresh === 'true'){
        updtate_schedule_refresh(schedule, false)
    }
}


