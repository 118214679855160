import React, { Component } from 'react';
import Slider from "react-slick";
import {validate_schedule} from '../.././Schedules'
import {get_full_image_path} from '../.././Common'
import './Thumbnail.css';


class Thumbnail extends Component{

    constructor(props){
        super(props);
        this.state = {
            slick : {
                setting : {
                    speed: 400,
                    slidesToShow: 3,
                    vertical: false,
                    autoplaySpeed: parseInt(props.zone.duration),
                    autoplay: true,
                    accessibility: false,
                    arrows: false,
                    fade: false,
                    pauseOnHover: false,
                    slidesToScroll: 1
                }
            }
        }
    }

    get_new(item){
        let newTag = "";
        if (item.is_new){
            newTag = <span className={'new_tag'}>*</span>
        }

        return newTag;
    }

    get_promo(item){
        let promoTag = "";
        if(item.promo){
            promoTag = <div className="promo_desc">{item.promo_description}</div>
        }
        return promoTag;
    }

    get_price_cut(item){
        let priceTag = "";
        if(validate_schedule(item) === false){
            priceTag = <div className="price">
                <div className={'price_col_1'}>
                    <div>{item.price_integer}</div>
                    <div>.{item.price_float}</div>
                </div>
            </div>
        } else {
            priceTag = <div className="price">
                <div className={'price_col_1'}>
                    <div>{item.promo_price_integer}</div>
                    <div>.{item.promo_price_float}</div>
                </div>
                <div className={"price_col_2 regular_price"}>
                    {item.price}$
                </div>
            </div>
        }

        return priceTag;
    }

    render(){
        let {slick} = this.state;
        return (
            <Slider {...slick.setting} className={'Thumbnail'}>
                {
                    this.props.zone.zone.map(item => (
                        <div className={'item'} key={item.item_id}>
                            <div className={'image'} style={{ background: `url(${get_full_image_path(item.small)})`}}>
                                <div className="info_wrapper">
                                    {/*<div className="numero">{item.row_nbr}</div>*/}
                                    <div className="name">
                                        <div>{item.name}</div>
                                    </div>

                                </div>
                                <div className={"price_zone " + validate_schedule(item)}>
                                    {this.get_new(item)}
                                    {this.get_promo(item)}
                                    {this.get_price_cut(item)}
                                </div>
                            </div>
                        </div>
                    ))}
            </Slider>
        )
    }
}
export default Thumbnail;
