import React, { Component } from 'react';
import Generic from './Generic';
import './Triple.css';
import Infos from "./Views/Infos";

class Triple extends Component{

    render(){
        return(
            <div>
                <Infos banner={this.props.banner}></Infos>
                <div className={'triple'}>
                    <div>
                        <div>
                            <Generic className={'zone1'} zone={this.props.zone1}></Generic>
                        </div>
                       <Generic className={'zone1'} zone={this.props.zone2}></Generic>
                    </div>
                    <Generic className={'zone2'} zone={this.props.zone3}></Generic>
                </div>
            </div>
        )
    }
}

export default Triple;