import React from 'react';
import Config from "../Config";
import {validate_schedule} from "./Schedules";
import {get_price_cut_default} from "./Prices";

export const get_full_image_path = (path) => {
    return Config.HTTP + path
}

export const get_position = (start, position) => {
    return parseInt(start) + parseInt(position);
}

export const get_new = (item) => {
    let newTag = "";
    if (item.is_new){
        newTag = <span className={'new_tag'}>*</span>
    }
    return newTag;
}

export const get_promo = (item) => {
    let promoTag = "";
    if(item.promo){
        promoTag = <div className="promo_desc">{item.promo_description}</div>
    }
    return promoTag;
}

export const get_image = (item) => {
    let image = "";
    if (item.small) {
        image =  <div className="image" style={{ background: `url(${get_full_image_path(item.small)})`}}></div>
    }
    return image;
}

export const get_description = (item, theme) => {
    let description = "";
    if (item.description) {
        description =  <div className={'description desc'} style={theme.description_theme}>{item.description}</div>
    }
    return description;
}

export const get_item_only_image = (item) => {
    let image = 'http://' + item.medium;
    let render = <div className={'item'} key={item.item_id}>
        <div className={'image'} key={item.row_nbr} style={{ background: `url(${image})`}}>

        </div>
    </div>
    return render
}

export const get_item = (zone, item, start_pos, show_pos, theme) => {
    let image = 'http://' + item.medium;
    let render = <div className={'item'} key={item.item_id}>
        <div className={'image'} key={item.row_nbr} style={{ background: `url(${image})`}}>
            <div className="info_wrapper">
                {get_numbering(item, theme, start_pos, show_pos)}
                <div className="name" style={theme.name_theme}>
                    <div>{item.name}</div>
                </div>

            </div>
            <div className={"price_zone " + validate_schedule(item)} style={theme.price_theme}>
                {get_new(item)}
                {get_promo(item)}
                {get_price_cut_default(item, theme)}
            </div>
        </div>
    </div>
    return render
}

export const dispatch_render = (zone, start_pos, show_pos, theme) => {
    let itemsGroup = []

    for (const item of zone) {
        if (item.is_image_only === 'false') {
            itemsGroup.push(get_item(zone, item, start_pos, show_pos, theme))
        } else {
            itemsGroup.push(get_item_only_image(item))
        }
    }
    return itemsGroup
}

export const get_numbering = (item, theme, start_position, show_pos) => {
    let numbering = "";
    if (show_pos === 'true'){
        numbering = <div className="numero" style={theme.number_theme}>{get_position(start_position, item.row_nbr)}</div>
    }
    return numbering;
}