import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Slider from "react-slick";
import {get_media_type, playDuration} from "../../Slide";
import './Medias.css';
const API_KEY = 'AIzaSyCk3OnmCRqLSpPvSXsYnHoy1obthSxrxL4';
class Medias extends Component{

    constructor(props){
        super(props);
        this.state = {
            dynamicId: "",
            settings : {
                speed: 600,
                slidesToShow: 1,
                vertical: false,
                autoplaySpeed: 4000,
                autoplay: true,
                accessibility: false,
                arrows: false,
                fade: true,
                pauseOnHover: false,
                slidesToScroll: 1,
                afterChange: current => playDuration(this.slider, this.state.dynamicId)
            },
            opts : {
                key: 'AIzaSyCk3OnmCRqLSpPvSXsYnHoy1obthSxrxL4',
                height: '100%',
                width: '100%',
                playerVars: {
                    autoplay: 0,
                    controls: 0,
                    rel: 0,
                    modestbranding: 0,
                    fs: 0,
                    iv_load_policy: 0,
                    showinfo: 0,
                    muted: 1
                }
            },
            videoId: 'video-' + new Date().valueOf()
        }
    }

    loadYoutubeApi() {
        const script = document.createElement("script");
        script.src = "https://apis.google.com/js/client.js";

        script.onload = () => {
            window.gapi.load('client', () => {
                window.gapi.client.setApiKey(API_KEY);
                window.gapi.client.load('youtube', 'v3', () => {
                    this.setState({ gapiReady: true });
                });
            });
        };

        document.body.appendChild(script);
    }

    componentDidMount(){
        const uniqueId = uuidv4();
        this.setState({ dynamicId: uniqueId });

        this.loadYoutubeApi();
    }

    render() {
        let content = this.props.campaigns.zone;
        let { dynamicId } = this.state;

        if (this.props.campaigns.layout === "Freedom") {
            content = this.props.campaigns.background;
        }
        return (
            <Slider ref={slider => (this.slider = slider)} className={`Medias ${dynamicId}`} {...this.state.settings}>
                {content.map(media => (get_media_type(media)))}
            </Slider>
        )
    }
}
export default Medias;

