import {validate_schedule} from "./Schedules";
import React from "react";

export const get_new = (item) => {
    let newTag = "";
    if (item.is_new){
        newTag = <span className={'new_tag'}>Nouveauté</span>
    }
    return newTag;
}


export const get_promo = (item) => {
    let promoTag = "";
    if(validate_schedule (item)  === true){
        promoTag = <div className="promo_desc">{item.promo_description}</div>

    }
    return promoTag;
}

export const get_price_cut  = (item, price_theme) => {
    let price_tag;
    let price_integer = ""
    let price_float   = ""

    if(item.price_default) {
        price_integer = item.price_default.split('.')[0]
        price_float   = item.price_default.split('.')[1]
    }


    if(validate_schedule(item) === false){
        price_tag = <div style={price_theme}>{price_integer}<span className={'float'}>.{price_float}</span></div>

    } else {
        price_tag = <div className={"wrapper_promo"}>
            <div className="regular_price" style={price_theme}>{price_integer}<span className={'float'}>.{price_float}</span></div>
            <div className="promo" style={price_theme}>{item.promo_price_integer}<span className={'float'}>.{item.promo_price_float}</span></div>
        </div>
    }
    return price_tag;
}

export const get_price_cut_v2 = (item, price_theme) => {
    let priceTag = "";
    if(!item.promo){
        priceTag = <div className="price" style={{ backgroundColor: `${price_theme[0].price_bg_color}`,color: `${price_theme[0].price_text_color}`}}>
            <div className={'price_wrapper'}>
                <div style={{ color: `${price_theme[0].price_text_color}`}}>{item.price_integer}</div>
                <div style={{ color: `${price_theme[0].price_text_color}`}}>.{item.price_float}</div>
            </div>
        </div>
    } else {
        priceTag = <div className="price" style={{ backgroundColor: `${price_theme[0].price_bg_color}`,color: `${price_theme[0].price_text_color}`}}>
            <div className={'price_wrapper'}>
                <div>{item.promo_price_integer}</div>
                <div>.{item.promo_price_float}</div>
            </div>
            <div className={"regular_price"}>{item.price}</div>
        </div>
    }
    return priceTag;
}

export const get_price_cut_default  = (item, theme) => {
    let priceTag = "";
    if(validate_schedule(item) === false){
        priceTag = <div className="price">
            <div className={'price_col_1'}>
                <div>{item.price_integer}</div>
                <div>{item.price_float}</div>
            </div>
        </div>
    } else {
        priceTag = <div className="price">
            <div className={'price_col_1'}>
                <div>{item.promo_price_integer}</div>
                <div>{item.promo_price_float}</div>
            </div>
            <div className={"price_col_2 regular_price"}>
                {item.price}$
            </div>
        </div>
    }
    return priceTag;
}

export const get_price_size = (size, size_caption,  price, size_theme, price_theme) => {
    let price_tag;
    let price_integer = price.split('.')[0]
    let price_float   = price.split('.')[1]

    if(size === 'small' && price !== '0.00'){
        price_tag = <div className={'small price'}><div className={'size'} style={size_theme}>{size_caption}</div><div style={price_theme} className={'r_price'}>{price_integer}<span className={'float'}>.{price_float}</span></div></div>
    }
    if(size === 'medium' && price !== '0.00'){
        price_tag = <div className={'medium price'}><div className={'size'} style={size_theme}>{size_caption}</div><div style={price_theme} className={'r_price'}>{price_integer}<span className={'float'}>.{price_float}</span></div></div>
    }
    if(size === 'large' && price !== '0.00'){
        price_tag = <div className={'large price'}><div className={'size'} style={size_theme}>{size_caption}</div><div style={price_theme} className={'r_price'}>{price_integer}<span className={'float'}>.{price_float}</span></div></div>
    }
    return price_tag
}

export const get_price = (item, size_theme, price_theme) => {
    let price_tag;
    if(item.is_multi_formats !== 'true'){
        price_tag = <div className={'default_price'}>
            {get_price_cut(item, price_theme) }
        </div>

    } else {
        price_tag = <div className={'prices'}>
            {get_price_size('small', item.price_small_caption, item.price_small, size_theme, price_theme)}
            {get_price_size('medium', item.price_medium_caption, item.price_medium, size_theme, price_theme)}
            {get_price_size('large', item.price_large_caption, item.price_large, size_theme, price_theme)}
        </div>
    }
    return price_tag;
}


