import React, { Component } from 'react';
import Slider from "react-slick";
import {
    get_full_image_path,
    get_new,
    get_promo,
    get_description
} from "../../Common.js";
import {get_theme} from "../../Theme.js";

import './Jacmel.css';
import {get_price} from "../../Prices";

class Jacmel extends Component{

    constructor(props){
        super(props);
        this.state = {
            slick : {
                setting : {
                    speed: 600,
                    slidesToShow: 1,
                    vertical: false,
                    autoplaySpeed: parseInt(props.zone.duration),
                    autoplay: true,
                    accessibility: false,
                    arrows: false,
                    fade: true,
                    pauseOnHover: false,
                    slidesToScroll: 1
                }
            }
        };
    }

    get_price_cut(item){
        let priceTag = "";
        if(!item.promo){
            priceTag = <div className="price">
                <div className={'price_col_1'}>
                    <div>{item.price_integer}</div>
                    <div>.{item.price_float}</div>
                </div>
            </div>
        } else {
            priceTag = <div className="price">
                <div className={'price_col_1'}>
                    <div>{item.promo_price_integer}</div>
                    <div>.{item.promo_price_float}</div>
                </div>
                <div className={"price_col_2 regular_price"}>
                    {item.price}$
                </div>
            </div>
        }

        return priceTag;
    }

    get_item(items, theme){
        let item_list   = []
        let item_group  = []
        let count       = 0
        let count_group = 0

        for (const item of items) {
            count       =  count + 1
            count_group =  count_group + 1
            let item_chunk = <div className={'item'} key={item.item_id} style={theme.background_color_theme}>
                <div className={'image'} style={{ background: `url(${get_full_image_path(item.small)})`}}>
                    <div className="numero" style={theme.number_theme}>{item.row_nbr}</div>
                    {get_new(item)}
                </div>
                <div className="info_wrapper">
                    <div className="name" style={theme.name_theme}>
                        <div>{item.name}</div>
                    </div>
                </div>
                <div className="price_zone_horizontal" style={theme.price_theme}>
                    {get_promo(item)}
                    {get_price(item, theme.size_theme, theme.price_theme)}
                    {get_description(item, theme)}
                </div>
            </div>

            if(count <= 2){
                item_group.push(item_chunk)
            }
            if (count === 2 ) {
                item_list.push(<div className={'bigGroup'}>{item_group}</div>)
                item_group = []
                count = 0
            }else if (count_group === items.length){
                item_list.push(<div className={'bigGroup'}>{item_group}</div>)
                item_group = []
            }
        }

        return item_list
    }

    render(){
        const theme = get_theme(this.props.zone.theme);

        const {slick} = this.state;
        let groupItems = this.get_item(this.props.zone.zone, theme)
        return(
            <Slider {...slick.setting} className={'Jacmel'}>
                {groupItems}
            </Slider>
        )
    }
}

export default Jacmel;
