import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import Config from './../Config'
class Screens extends Component {

    constructor(props) {
        super(props)
        this.state = {
            client_id :  localStorage.getItem('client_id'),
            error: null,
            isLoaded: false,
            items: [],
            displays: ''
        }
    }

    deleteSession(){
        localStorage.clear()
        window.location.href = "/";
    }

    componentDidMount() {
        const data = {
            method        : 'get_screens',
            client_id : this.state.client_id,
        }

        let formData = new FormData();
        for(let i in data){
            formData.append(i,data[i]);
        }

        fetch(Config.API,{
            method: 'POST',
            body: formData,
        })
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    isLoaded: true,
                    items: result.screens
                })
            },
            (error) => {
                this.setState({
                    isLoaded: true,
                    error
                })
            }
        )
    }

    render() {
    const { items } = this.state
        return (
            <nav className="displays">
            {items.map(item => (
                <Link to={'/display/'+item.id} key={item.id}>
                    <div className="display">
                    <div className="screen">{item.name}</div>
                    </div>
                </Link>
            ))}
                <div className="logout" onClick={this.deleteSession}><div className={'icon'}></div></div>
            </nav>
        )
    }
}
export default Screens;
