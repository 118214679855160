import React, { Component } from 'react';
import Generic from './Generic';
import Infos from "./Views/Infos";
import './Plus.css';
class Plus extends Component {

    render(){
        return(
            <div>
                <Infos banner={this.props.banner}></Infos>
                <div className={'Plus'}>
                    <div className={'left_col'}>
                        <Generic className={'zone1'} zone={this.props.zone1}></Generic>
                    </div>
                    <div className={'right_col'}>
                        <Generic className={'zone2'} zone={this.props.zone2}></Generic>
                        <Generic className={'zone3'} zone={this.props.zone3}></Generic>
                    </div>
                </div>
            </div>
        )
    }
}

export default Plus;

