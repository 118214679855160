import React, { Component } from 'react';
import Config from './Config';
import catodik_noise from './assets/videos/catodik_noise.mp4'
import {Route, HashRouter, Switch} from 'react-router-dom';
import { Redirect } from 'react-router-dom'
import './assets/fonts/roboto/stylesheet.css';
import './assets/fonts/kenzo/stylesheet.css';
import './App.css';
import './assets/icons/icon.jpg';
import Screens from './components/Screens';
import Display from './components/Display';
/* Docs: https://react-slick.neostack.com/docs/api#accessibility */

class App extends Component {
    constructor(props){
        super(props)
        this.display_menu = this.display_menu.bind(this);
        let d = new Date();

        this.state = {
            logged_in : false,
            username : '',
            cached : {
                user_id : '',
                logo: '',
                client_id: '',
                status: '',
                logged_in: 'show'
            },
            copyright: '2016 - '+ d.getFullYear() + ' CATODIK. All rights reserved',
            menu_visibility : 'hide'
        }
        this.setUsername = this.setUsername.bind(this);
        this.setPassword = this.setPassword.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    renderRedirect = () => {
        return <HashRouter>
        <Redirect to={localStorage.getItem('last_page_url')} />
        </HashRouter>
    }

    display_menu(event){
        if(event.keyCode === 32) {
            if(this.state.menu_visibility === 'hide'){
                this.setState({menu_visibility: ''})
            }else{
                this.setState({menu_visibility: 'hide'})
            }
        }
    }

    hideMenu() {
        this.setState({menu_visibility: 'hide'})
    }

    componentDidMount(){
        document.addEventListener("keydown", this.display_menu, false);

        this.interval = setInterval(() => {
            this.hideMenu()
        }, 10000);

        this.setState({
            cached: {
                user_id: localStorage.getItem("user_id"),
                logo: localStorage.getItem("logo"),
                client_id: localStorage.getItem("client_id"),
                status: localStorage.getItem("status"),
                logged_in: localStorage.getItem("logged_in"),
            }
        })
    }
    componentWillUnmount(){
        document.removeEventListener("keydown", this.display_menu, false);
    }

    setUsername(event) {
        this.setState({username: event.target.value})
    }
    setPassword(event) {
        this.setState({password: event.target.value})
    }

    handleSubmit(event){
        event.preventDefault()
        const data = new FormData(event.target)
        fetch(Config.API, {
            method: 'POST',
            body: data,
        }).then(res => res.json())
        .then((result) => {
            localStorage.setItem('user_id', result.user_id)
            localStorage.setItem('logo',Config.DOMAIN + '/' + result.logo)
            localStorage.setItem('client_id', result.client_id)
            localStorage.setItem('status', result.status)
            localStorage.setItem('logged_in', result.logged_in)

            this.setState({
                cached: {
                    user_id: localStorage.getItem("user_id"),
                    logo: localStorage.getItem("logo"),
                    client_id: localStorage.getItem("client_id"),
                    status: localStorage.getItem("status"),
                    logged_in: localStorage.getItem("logged_in")
                }
            })
        },(error) => {
            this.setState({
                error
            });

        }).catch(rejected => {
            console.log(rejected)
        })
    }
    login = () => {
        const {username, password, copyright} =  this.state
        return (
            <HashRouter>
                <div className={'App Login'}>
                    <div className={'login_form'}>
                        <form onSubmit={this.handleSubmit}>
                            <input type="text"  name="username" value={username} placeholder="Username" onChange={this.setUsername}/>
                            <input type="password"  name="password" value={password} placeholder="Password" onChange={this.setPassword}/>
                            <input type="hidden"  name="method" value={'get_login'}/>
                            <input className={'submit'} type="submit" name="save" value="Sign in"/>
                        </form>
                    </div>
                    <video className={'video_background'} width="100%" height="100%" autoPlay loop muted>
                        <source src={catodik_noise} type="video/mp4"/>
                    </video>
                    <div className={'copyright'}>{copyright}</div>
                </div>
            </HashRouter>
        )
    };
    main = () => {
        const {cached} = this.state
        return(
        <HashRouter>
            <div className={'App'}>
                <div id={'displays'} className={this.state.menu_visibility}><Screens cached={cached} ></Screens></div>
                <div className={'instructions'}>
                    <div className={'space_key'}></div>
                    <div v className={'note'}>
                        <div>Hit the spacebar to display or hide the navigation.</div>
                        <div>Appuyez sur la barre d'espace pour afficher ou cacher la navigation.</div>
                    </div>
                    <div className={'logo'}></div>
                </div>
                <Switch>
                    <Route path='/display/:id' component = {Display}/>
                </Switch>
            </div>
        </HashRouter>
        )
    };
    dispatch = () => {
        if(localStorage.getItem('logged_in') === 'true') {
            return this.main()
        }else {
            return this.login()
        }
    };

    render() {
        return this.dispatch()
    }
}
export default App;