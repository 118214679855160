export const get_background_color = (theme) => {
    let t = theme[0];
    if (theme[0] === undefined){
        return true;
    }
    return (t.background_color !== undefined) ? t.background_color : '';
}
export const get_background_image = (theme) => {
    let t = theme[0];
    if (theme[0] === undefined){
        return 'transparent';
    }
    return (t.background_color !== undefined) ? `url(http://${t.large})` : '';
}

export const get_header_color = (theme) => {
    let t = theme[0];
    if (theme[0] === undefined){
        return true;
    }
    return (t.header_text_color !== undefined) ? t.header_text_color : '';
}

export const get_header_bg_color = (theme) => {
    let t = theme[0];
    if (theme[0] === undefined){
        return true;
    }
    return (t.header_bg_color !== undefined) ? t.header_bg_color : '';
}

export const get_header_font = (theme) => {
    let t = theme[0];
    if (theme[0] === undefined){
        return true;
    }
    return (t.header_text_font !== undefined) ? t.header_text_font : '';
}

export const get_name_color = (theme) => {
    let t = theme[0];
    if (theme[0] === undefined){
        return true;
    }
    return (t.name_text_color !== undefined) ? t.name_text_color : '';
}

export const get_name_font = (theme) => {
    let t = theme[0];
    if (theme[0] === undefined){
        return true;
    }
    return (t.name_text_font !== undefined) ? t.name_text_font : '';
}
export const get_name_bg_color = (theme) => {
    let t = theme[0];
    if (theme[0] === undefined){
        return true;
    }
    return (t.name_bg_color !== undefined) ? t.name_bg_color : 'transparent';
}

export const get_number_color = (theme) => {
    let t = theme[0];
    if (theme[0] === undefined){
        return true;
    }
    return (t.number_text_color !== undefined) ? t.number_text_color : '';
}

export const get_number_font = (theme) => {
    let t = theme[0];
    if (theme[0] === undefined){
        return true;
    }
    return (t.number_text_font !== undefined) ? t.number_text_font : '';
}

export const get_number_bg_color = (theme) => {
    let t = theme[0];
    if (theme[0] === undefined){
        return true;
    }
    return (t.number_bg_color !== undefined) ? t.number_bg_color : 'transparent';
}

export const get_description_color = (theme) => {
    let t = theme[0];
    if (theme[0] === undefined){
        return true;
    }
    return (t.description_text_color !== undefined) ? t.description_text_color : '';
}

export const get_description_font = (theme) => {
    let t = theme[0];
    if (theme[0] === undefined){
        return true;
    }
    return (t.description_text_font !== undefined) ? t.description_text_font : '';
}

export const get_description_bg_color = (theme) => {
    let t = theme[0];
    if (theme[0] === undefined){
        return true;
    }
    return (t.description_bg_color !== undefined) ? t.description_bg_color : 'transparent';
}

export const get_size_color = (theme) => {
    let t = theme[0];
    if (theme[0] === undefined){
        return true;
    }
    return (t.size_text_color !== undefined) ? t.size_text_color : '';
}

export const get_size_font = (theme) => {
    let t = theme[0];
    if (theme[0] === undefined){
        return true;
    }
    return (t.size_text_font !== undefined) ? t.size_text_font : '';
}

export const get_size_bg_color = (theme) => {
    let t = theme[0];
    if (theme[0] === undefined){
        return true;
    }
    return (t.size_bg_color !== undefined) ? t.size_bg_color : 'transparent';
}

export const get_price_color = (theme) => {
    let t = theme[0];
    if (theme[0] === undefined){
        return true;
    }
    return (t.price_text_color !== undefined) ? t.price_text_color : '';
}

export const get_price_font = (theme) => {
    let t = theme[0];
    if (theme[0] === undefined){
        return true;
    }
    return (t.price_text_font !== undefined) ? t.price_text_font : '';
}

export const get_price_bg_color = (theme) => {
    let t = theme[0];
    if (theme[0] === undefined){
        return true;
    }
    return (t.price_bg_color !== undefined) ? t.price_bg_color : 'transparent';
}