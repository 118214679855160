import React, { Component } from 'react';
import Config from './../Config'
import './Layouts/Layout.css';
import logo from '../assets//images/logo_white.svg'
import {validate_schedule_display} from './Schedules'
import One from './Layouts/One';
import Dual from './Layouts/Dual';
import Triple from './Layouts/Triple';
import Classic from './Layouts/Classic';
import Ensemble from './Layouts/Ensemble';
import Horizontal from './Layouts/Horizontal';
import Plus from './Layouts/Plus';
import Freedom from './Layouts/Freedom';
import Overlay from './Layouts/Overlay';
class Display extends Component {

    constructor(props){
        super(props);

        this.state = {
            error: null,
            isLoaded: false,
            client_id : localStorage.getItem('client_id'),
            last_page: '',
            layouts :  [],
            zone1 : {
                zoneTitle: [],
                zone: []
            },
            zone2 : {
                zoneTitle: [],
                zone: []
            },
            zone3 : {
                zoneTitle: [],
                zone: []
            },
            zone4 : {
                zoneTitle: [],
                zone: []
            },
            campaign: [],
            promos: [],
            refresh_hash: 0,
            promos_status : []
        };
    }

    loadDisplay (screen_id){
        const data = {
            'method'     : 'get_display',
            'screen_id'  : screen_id,
            'client_id'  : localStorage.getItem('client_id')
        };

        let fd = new FormData();
        //very simply, doesn't handle complete objects
        for(let i in data){
            fd.append(i,data[i]);
        }
        fetch(Config.API,{
            method: 'POST',
            dataType: 'html',
            body: fd
        })
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    isLoaded: true,
                    layouts: result.layouts,
                    layout_bg: result.layout_bg,
                    banner: result.banner,
                    zone1 : {
                        zoneTitle: result.zone1.title,
                        zone: result.zone1.zone,
                        view: result.zone1.view,
                        theme: result.zone1.theme,
                        start_pos: result.zone1.start_pos,
                        slides_to_show: result.zone1.slides_to_show,
                        show_pos: result.zone1.show_pos,
                        duration: result.zone1.duration,
                        width: result.zone1.width,
                        height: result.zone1.height,
                        x_axis: result.zone1.x_axis,
                        y_axis: result.zone1.y_axis,
                        is_visible: result.zone1.is_visible
                    },
                    zone2 : {
                        zoneTitle: result.zone2.title,
                        zone: result.zone2.zone,
                        view: result.zone2.view,
                        theme: result.zone2.theme,
                        start_pos: result.zone2.start_pos,
                        show_pos: result.zone2.show_pos,
                        slides_to_show: result.zone2.slides_to_show,
                        duration: result.zone2.duration,
                        width: result.zone2.width,
                        height: result.zone2.height,
                        x_axis: result.zone2.x_axis,
                        y_axis: result.zone2.y_axis,
                        is_visible: result.zone2.is_visible
                    },
                    zone3 : {
                        zoneTitle: result.zone3.title,
                        zone: result.zone3.zone,
                        view: result.zone3.view,
                        theme: result.zone3.theme,
                        start_pos: result.zone3.start_pos,
                        show_pos: result.zone3.show_pos,
                        slides_to_show: result.zone3.slides_to_show,
                        duration: result.zone3.duration,
                        width: result.zone3.width,
                        height: result.zone3.height,
                        x_axis: result.zone3.x_axis,
                        y_axis: result.zone3.y_axis,
                        is_visible: result.zone3.is_visible
                    },
                    zone4 : {
                        zoneTitle: result.zone4.title,
                        zone: result.zone4.zone,
                        view: result.zone4.view,
                        theme: result.zone4.theme,
                        start_pos: result.zone4.start_pos,
                        show_pos: result.zone4.show_pos,
                        slides_to_show: result.zone4.slides_to_show,
                        duration: result.zone4.duration,
                        width: result.zone4.width,
                        height: result.zone4.height,
                        x_axis: result.zone4.x_axis,
                        y_axis: result.zone4.y_axis,
                        is_visible: result.zone4.is_visible
                    },
                    campaign: {
                        content: result.campaigns.content,
                        interval: result.campaigns.interval,
                    },
                    schedules: result.schedules
                });

                this.validate_schedules(result.schedules);

            },
            (error) => {
                this.setState({
                    isLoaded: true,
                    error : {
                        message : "Something went wrong with CATODIK."
                    }
                });
            }
        );
    }

    validate_schedules (schedules){
        if (schedules) {
            for (let i = 0; i < schedules.length; i++) {
                validate_schedule_display(schedules[i])
            }
        }
    }

    refresh() {
        this.validate_schedules(this.state.schedules)
        let refresh_key = localStorage.getItem('refresh_hash')
        let url =  window.location.href
        let param = url.split('/')
        let current_url = '/' + param[param.length-2]+'/'+ param[param.length-1]
        localStorage.setItem('last_page_url', current_url);

        let data = {
            'method'      : 'get_refresh',
            screen_id     : this.props.match.params.id,
            client_id : localStorage.getItem('client_id'),
        };

        let fd = new FormData();
        for(let i in data){
            fd.append(i,data[i]);
        }
        fetch(Config.API,{
            method: 'POST',
            dataType: 'html',
            body: fd
        })
        .then(res => res.json())
        .then(
            (result) => {

                if(refresh_key !== result.refresh_hash){
                    localStorage.setItem('refresh_hash', result.refresh_hash)
                    window.location.reload();
                }

            },
            (error) => {
                this.setState({
                    error
                });
            }
        ).catch(rejected => {
            window.location.reload()
        })
    }

    componentDidMount() {
        this.loadDisplay(this.props.match.params.id);

        this.interval = setInterval(() => {
            this.refresh()
        }, 30000);

        /* Refresh each 2 hours to avoid memory leak */
        this.interval = setInterval(() => {
            window.location.reload()
        }, 7200000);
    }

    componentWillReceiveProps(nextProps){
        this.loadDisplay(nextProps.match.params.id);
    }

    getLayout(layout) {
        switch (layout) {
            case 'One':
                return <One zone1={this.state.zone1} banner={this.state.banner}></One>;
            case 'Dual':
                return <Dual zone1={this.state.zone1} zone2={this.state.zone2 } banner={this.state.banner}></Dual>;
            case 'Triple':
                return <Triple zone1={this.state.zone1} zone2={this.state.zone2} zone3={this.state.zone3} banner={this.state.banner}></Triple>;
            case 'Classic':
                return <Classic zone1={this.state.zone1} zone2={this.state.zone2 } banner={this.state.banner}></Classic>;
            case 'Ensemble':
                return <Ensemble zone1={this.state.zone1} zone2={this.state.zone2 }  zone3={this.state.zone3} zone4={this.state.zone4} banner={this.state.banner}></Ensemble>;
            case 'Horizontal':
                return <Horizontal zone1={this.state.zone1} zone2={this.state.zone2 } zone3={this.state.zone3 } banner={this.state.banner}></Horizontal>;
            case 'Plus':
                return <Plus zone1={this.state.zone1} zone2={this.state.zone2} zone3={this.state.zone3} banner={this.state.banner}></Plus>;
            case 'Freedom':
                return <Freedom layout={layout} background={this.state.layout_bg} zone1={this.state.zone1} zone2={this.state.zone2 }  zone3={this.state.zone3} zone4={this.state.zone4} banner={this.state.banner}></Freedom>;
            default:
                return null;
        }
    }

    render(){
        const {error, isLoaded, layouts, campaign} = this.state;
        let layoutView = "";

        if (error) {
            return <div id="overlay" className="overlay">
                <div className="loader_container">
                    <div className="loader_logo_error" style={{backgroundImage: `url(${logo})`}}></div>
                    <div className="loader_error_text"><span className="accent">Error!</span> {error.message}</div>
                    <div className="loader_error_text">Contact: <span className="accent">support@catodik.com</span> | <span className="accent">514-268-9618</span></div>
                </div>
            </div>
        } else if (!isLoaded) {
            return <div id="overlay" className="overlay">
                        <div className="loader_container">
                            <div className="loader_logo" style={{backgroundImage: `url(${localStorage.getItem('logo')})`}}></div>
                            <div className="loader">Loading...</div>
                        </div>
                    </div>
        } else {
            layouts.map(layout => (
                layoutView = this.getLayout(layout.name)
            ));

            return <div><Overlay campaign={campaign}></Overlay><div className="layout">{layoutView}</div></div>
        }
    }
}

export default Display