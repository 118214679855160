import React, { Component } from 'react';
import Config from './../../../Config'
import Slider from "react-slick";
import Infos from './Infos';
import './Feeds.css';
class Feeds extends Component{

    constructor(props){
        super(props);
        this.state = {
            isLoaded: false,
            feeds :  [],
            slick : {
                setting : {
                    speed: 600,
                    slidesToShow: 1,
                    vertical: false,
                    autoplaySpeed: 4000,
                    autoplay: true,
                    accessibility: false,
                    arrows: false,
                    fade: true,
                    pauseOnHover: false,
                    slidesToScroll: 1
                }
            }
        }
    }

    //To do: manage when they is no data in php file
    componentDidMount(){
        const data = {
            method : 'get_feeds'
        }

        let formData = new FormData();
        for(let i in data){
            formData.append(i,data[i]);
        }

        fetch(Config.INMENU_API,{
            method: 'POST',
            body: formData,
        })
        .then(res => res.json())
        .then(
            (result) => {

                this.setState({
                    isLoaded: true,
                    feeds : result.feeds
                })

            },
            (error) => {
                this.setState({
                    isLoaded: false,
                })
            }
        )
    }

    getFeeds (feeds, slick) {
        let content = <div>NA</div>
        if(feeds !== ''){
            content = <Slider {...slick.setting}>
                {
                    feeds.map(feed => (
                        <div className={'feed'}>
                            <div className={'channel_title'}>{feed.channel_title}</div>
                            <div className={'item_title'}>{feed.item_title}</div>
                            <div className={'image'} style={{ background: `url(${feed.image})`}}>
                                <img  className={'channel_image'} alt={feed.channel_title} src={feed.channel_image}/>
                            </div>
                            <div className={'description'}>{feed.description}</div>
                        </div>
                    ))}
            </Slider>
        }

        return content
    }

    render(){
        let {slick} = this.state;
        return(
            <div className={'feeds'} >
                <div className={'client_logo'}><img alt="logo" src={localStorage.getItem('logo')}/></div>
                {this.getFeeds(this.state.feeds, slick)}
                <Infos logo={false}></Infos>
            </div>
        )
    }
}

export default Feeds;