import React, { Component } from 'react';
import Slider from "react-slick";
import {dispatch_render} from "../../Common.js";
import {get_theme} from "../../Theme.js";
import './Default.css';

class Default extends Component{

    constructor (props){
        super(props)
        this.state = {
            promo : false,
            slick : {
                setting : {
                    speed: 600,
                    slidesToShow: 1,
                    vertical: false,
                    autoplaySpeed: parseInt(props.zone.duration),
                    autoplay: true,
                    accessibility: false,
                    arrows: false,
                    fade: true,
                    pauseOnHover: false,
                    slidesToScroll: 1
                }
            }
        }
    }

    render(){
        const {slick} = this.state;
        const {zone, start_pos, show_pos}  = this.props.zone;
        const theme   = get_theme(this.props.zone.theme);
        return(
            <Slider className={'Default'} {...slick.setting}>
                {dispatch_render(zone, start_pos, show_pos, theme)}
            </Slider>
        )
    }
}

export default Default;
