import React, { Component } from 'react';
import Default   from './Views/Default';
import Extras    from './Views/Extras';
import Jacmel    from './Views/Jacmel';
import Kijuju    from './Views/Kijuju';
import Lascaobas from './Views/Lascaobas';
import List      from './Views/List';
import Thumbnail from './Views/Thumbnail';
import Medias        from "./Views/Medias";
import Messages  from "./Views/Messages";

class Generic extends Component{

    get_view(setting) {
        let view = <Messages></Messages>;

        if(typeof setting.layout !== 'undefined') {
            if (setting.layout === "Freedom") {
                view = <Medias campaigns={setting}></Medias>
            }
        } else {
            if(typeof setting !== 'undefined') {
                if (typeof setting.view[0] !== 'undefined') {
                    switch (setting.view[0].name.toLowerCase()) {
                        case 'default':
                            view = <Default zone={setting}></Default>
                            break;
                        case 'extras':
                            view = <Extras zone={setting}></Extras>
                            break;
                        case 'jacmel':
                            view = <Jacmel zone={setting}></Jacmel>
                            break;
                        case 'kijuju':
                            view = <Kijuju zone={setting}></Kijuju>
                            break;
                        case 'lascaobas':
                            view = <Lascaobas zone={setting}></Lascaobas>
                            break;
                        case 'list':
                            view = <List zone={setting}></List>
                            break;
                        case 'thumbnail':
                            view = <Thumbnail zone={setting}></Thumbnail>
                            break;
                        default:
                            view = "";
                    }
                }
            }

            if(typeof setting.zone[0] !== 'undefined') {
                // content is media
                if (typeof setting.zone[0].type !== 'undefined') {
                    if (setting.zone[0].type === "2") {
                        view = <Medias campaigns={setting}></Medias>
                    }
                }
            }
        }
        return view;
    }

    render(){
        return (
          this.get_view(this.props.zone)
        )
    }
}

export default Generic;