import React, { Component } from 'react';

class Messages extends Component{

    constructor(props){
        super(props);
        this.state = {}
    }

    render(){
        return (
            <div></div>
        )
    }
}
export default Messages;
