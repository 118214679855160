import React, { Component } from 'react';
import Generic from "./Generic";
import './Freedom.css';
import Infos from "./Views/Infos";

class Freedom extends Component{

    constructor(props) {
        super(props);
        this.state = { width: 0, height: 0 };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    render(){
        let config = this.props;
        let width  = this.state.width;
        let height = 810;

        const mystyle = {
            backgroundColor: "",
            backgroundImage: `url(http://${this.props.background})`
        };

        let zone_config1 = {
            width: `${config.zone1.width * width / 860}px`,
            height: `${config.zone1.height * height / 484}px`,
            transform: `translate(${config.zone1.x_axis * width / 860}px, ${config.zone1.y_axis * height / 484}px)`
        }
        let zone_config2 = {
            width: `${config.zone2.width * width / 860}px`,
            height: `${config.zone2.height * height / 484}px`,
            transform: `translate(${config.zone2.x_axis * width / 860}px, ${config.zone2.y_axis * height / 484}px)`
        }
        let zone_config3 = {
            width: `${config.zone3.width * width / 860}px`,
            height: `${config.zone3.height * height / 484}px`,
            transform: `translate(${config.zone3.x_axis * width / 860}px, ${config.zone3.y_axis * height / 484}px)`
        }
        let zone_config4 = {
            width: `${config.zone4.width * width / 860}px`,
            height: `${config.zone4.height * height / 484}px`,
            transform: `translate(${config.zone4.x_axis * width / 860}px, ${config.zone4.y_axis * height / 484}px)`
        }

        let layout = {
            layout: this.props.layout,
            background: this.props.background
        }

        let zone1 = "";
        if(this.props.zone1.is_visible) {
            zone1 =  <div style={zone_config1}><Generic className={'zone1'}  zone={this.props.zone1}></Generic></div>;
        }
        let zone2 = "";
        if(this.props.zone2.is_visible) {
            zone2 =  <div style={zone_config2}><Generic className={'zone2'}  zone={this.props.zone2}></Generic></div>;
        }
        let zone3 = "";
        if(this.props.zone3.is_visible) {
            zone3 =  <div style={zone_config3}><Generic className={'zone3'}  zone={this.props.zone3}></Generic></div>;
        }
        let zone4 = "";
        if(this.props.zone4.is_visible) {
            zone4 =  <div style={zone_config4}><Generic className={'zone4'}  zone={this.props.zone4}></Generic></div>;
        }
        return(
            <div>
                <Infos banner={this.props.banner}></Infos>
                <div className={'Freedom'} style={mystyle}>
                    <div className={'background'}>
                        <Generic className={'campaign'} zone={layout}></Generic>
                    </div>
                    <div className={'wrapper'}>
                        {zone1}
                        {zone2}
                        {zone3}
                        {zone4}
                    </div>
                </div>
            </div>
        )
    }
}

export default Freedom;