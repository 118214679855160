import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Slider from "react-slick";
import './Overlay.css';
import {get_media_type} from "../Slide";

class Overlay extends Component{
    constructor(props){
        super(props);
        this.state = {
            showOverlay: false,
            dynamicId: "",
            content: this.props.campaign.content,
            interval: this.props.campaign.interval,
            settings : {
                speed: 600,
                slidesToShow: 1,
                vertical: false,
                autoplaySpeed: 4000,
                autoplay: true,
                accessibility: false,
                arrows: false,
                fade: true,
                pauseOnHover: false,
                slidesToScroll: 1,
                afterChange : current => this.playDuration(this.slider, this.state.dynamicId),
                beforeChange: (currentSlide, nextSlide) => {
                    if (nextSlide === 0) {
                        this.setShowOverlay(false);
                    }
                },
            },
            opts : {
                key: 'AIzaSyCk3OnmCRqLSpPvSXsYnHoy1obthSxrxL4',
                height: '100%',
                width: '100%',
                playerVars: {
                    autoplay: 0,
                    controls: 0,
                    rel: 0,
                    modestbranding: 0,
                    fs: 0,
                    iv_load_policy: 0,
                    showinfo: 0,
                    muted: 1
                }
            }
        }
    }

    setShowOverlay = (isOverlay) => {
        this.setState({showOverlay: isOverlay});
    }

    componentDidMount(){
        const uniqueId = uuidv4();
        this.setState({ dynamicId: uniqueId });

        this.interval = setInterval(() => {
            if (this.state.showOverlay === false) {
                if (this.state.content !== '' && this.state.interval !== '') {
                    this.setShowOverlay(true)
                }
            }
        }, parseInt(this.state.interval));
    }

    playDuration(slider, dynamicId) {
        let id = document.getElementsByClassName(`${dynamicId}`)[0]
        let slick_current = id.getElementsByClassName('slick-current')[0]
        if(slick_current !== undefined) {
            if (slick_current.getElementsByClassName('video_MP4')[0] !== undefined) {
                let video_tag = slick_current.getElementsByClassName('video_MP4')[0]
                let video_id = video_tag.getAttribute('id');
                if (video_id !== '') {
                    let video = document.getElementById(video_id)
                    slider.slickPause()
                    video.play()
                    video.addEventListener('ended', function () {
                        slider.slickNext()
                        slider.slickPlay()
                    })
                }
            }

            if(slick_current.getElementsByClassName('image_wrapper')[0] !== undefined) {
                let imageTag  = slick_current.getElementsByClassName('image_wrapper')[0]
                let duration  = imageTag.getAttribute('data-duration');
                slider.slickPause()
                setTimeout(function(){
                    slider.slickNext()
                    slider.slickPlay()
                }, duration);
            }

            if(slick_current.getElementsByClassName('video_YOUTUBE')[0] !== undefined) {
                let video_tag = slick_current.getElementsByClassName('video_YOUTUBE')[0]
                let video_id = video_tag.getAttribute('id');
                if (video_id !== '') {
                    //todo
                }
                //todo
            }

            if(slick_current.getElementsByClassName('video_VIMEO')[0] !== undefined) {
                //todo
            }
        }
    }
    render() {
        let { dynamicId, showOverlay, content} = this.state;
        return(
            <div>
                {showOverlay && (
                    <div className="overlay_campaign">
                        <Slider id={dynamicId} ref={slider => (this.slider = slider)} className={`Medias ${this.state.dynamicId}`} {...this.state.settings}>
                            {content.map(media => (get_media_type(media, dynamicId)))}
                        </Slider>
                    </div>
                )}
            </div>
        )
    }
}

export default Overlay;