import React, { Component } from 'react';
import Generic from "./Generic";
import Infos from "./Views/Infos";
import './Ensemble.css';

class Ensemble extends Component{

    render(){
        return(
            <div className={'Ensemble'}>
                <Infos banner={this.props.banner}></Infos>
                <div className={'wrapper'}>
                    <Generic className={'zone1'} zone={this.props.zone1}></Generic>
                    <Generic className={'zone2'} zone={this.props.zone2}></Generic>
                    <Generic className={'zone3'} zone={this.props.zone3}></Generic>
                </div>
                <Generic zone={this.props.zone4}></Generic>
            </div>
        )
    }
}

export default Ensemble;