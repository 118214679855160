import React, { Component } from 'react';
import Slider from "react-slick";
import {validate_schedule} from '../.././Schedules'
import {get_description, get_full_image_path} from '../.././Common'
import {get_theme} from "../../Theme.js";
import {get_price, get_promo} from "../../Prices";
import './Kijuju.css';

class Kijuju extends Component{

    constructor(props){
        super(props)
        this.state = {
            promo: '',
            slick : {
                setting : {
                    speed: 400,
                    slidesToShow: 1,
                    vertical: false,
                    autoplaySpeed: parseInt(props.zone.duration),
                    autoplay: true,
                    accessibility: false,
                    arrows: false,
                    fade: true,
                    pauseOnHover: false,
                    slidesToScroll: 1
                }
            }
        }
    }

    get_item(items, theme){
        let item_list   = []
        let item_group  = []
        let count       = 0
        let count_group = 0
        let item_chunk = "";

        for (const item of items) {
            count       =  count + 1
            count_group =  count_group + 1

            if(item.is_image_only !== 'true') {
                item_chunk = <div className={'item'} key={count}>
                    <div className={'image'} style={{ background: `url(${get_full_image_path(item.small)})`}}>
                        {get_promo(item)}
                        <div className="info_wrapper">
                            <div className="numero" style={theme.number_theme}>{item.row_number}</div>
                            <div className="name" style={theme.name_theme}>
                                <div>{item.name}</div>
                            </div>
                            {get_description(item, theme)}
                        </div>
                        <div className={"price_zone " + validate_schedule(item)} style={theme.price_theme}>
                            {get_price(item, theme.size_theme, theme.price_theme)}
                        </div>
                    </div>
                </div>
            } else {
                item_chunk = <div className={'item'} key={count}>
                    <div className={'image'} style={{ background: `url(${get_full_image_path(item.small)})`}}>
                    </div>
                </div>
            }



            if(count <= 12) {
                item_group.push(item_chunk)
            }

            if (count === 12 ) {
                item_list.push(<div className={'bigGroup'}>{item_group}</div>)
                item_group = []
                count = 0
            }else if (count_group === items.length){
                item_list.push(<div className={'bigGroup'}>{item_group}</div>)
                item_group = []
            }
        }

        return item_list
    }

    render(){
        const {slick}    = this.state
        const {zone}     = this.props.zone
        const theme      = get_theme(this.props.zone.theme);

        const groupItems = this.get_item(zone,theme)

        return (
            <div className={'list_wrapper'}>
                <div className={'list_background color'} style={theme.background_color_theme}></div>
                <div className={'list_background image'} style={theme.background_image_theme}></div>
                <Slider {...slick.setting} className={'Kijuju'}>
                    {groupItems}
                </Slider>
            </div>

        )
    }
}

export default Kijuju;
