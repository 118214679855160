import {
    get_background_color,
    get_background_image,
    get_description_bg_color,
    get_description_color,
    get_description_font,
    get_header_bg_color,
    get_header_color,
    get_header_font,
    get_name_bg_color,
    get_name_color,
    get_name_font,
    get_number_bg_color,
    get_number_color,
    get_number_font,
    get_price_bg_color,
    get_price_color,
    get_price_font,
    get_size_bg_color,
    get_size_color,
    get_size_font
} from "./Styles";

export const get_theme = (theme) => {
    return {
        background_color_theme : {
            backgroundColor: get_background_color(theme)
        },
        background_image_theme : {
            backgroundImage: get_background_image(theme)
        },
        header_theme : {
            color: get_header_color(theme),
            fontFamily: get_header_font(theme),
            backgroundColor: get_header_bg_color(theme)
        },
        name_theme : {
            color: get_name_color(theme),
            fontFamily: get_name_font(theme),
            backgroundColor: get_name_bg_color(theme)
        },
        number_theme : {
            color: get_number_color(theme),
            fontFamily: get_number_font(theme),
            backgroundColor: get_number_bg_color(theme)
        },
        description_theme : {
            color: get_description_color(theme),
            fontFamily: get_description_font(theme),
            backgroundColor: get_description_bg_color(theme)
        },
        size_theme : {
            color: get_size_color(theme),
            fontFamily: get_size_font(theme),
            backgroundColor: get_size_bg_color(theme)
        },
        price_theme : {
            color: get_price_color(theme),
            fontFamily: get_price_font(theme),
            backgroundColor: get_price_bg_color(theme)
        }
    }
}